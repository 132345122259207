import { Injectable } from '@angular/core';

import { ThemeParameters } from '@core/interfaces/theme-parameters';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() {}

  setThemeParameters(themeParameters: ThemeParameters): void {
    const root = document.documentElement;

    if (themeParameters.primaryColor)
      root.style.setProperty('--primary-color', themeParameters.primaryColor);
    if (themeParameters.secondaryColor)
      root.style.setProperty('--secondary-color', themeParameters.secondaryColor);
  }
}
