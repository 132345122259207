import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { ThemeParameters } from '@core/interfaces/theme-parameters';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { environment } from '@environment';
import { OrganizationViewModel, ProfileOrganization } from '@features/models/organization.model';
import { Result } from '@shared/models/result.model';
import { LOCAL_STORAGE_KEYS } from '@shared/utils/constants';

import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private url = `${environment.apis.linkroom}/organizations`;

  private _currentOrganization: OrganizationViewModel | null = null;
  private _currentOrganization$ = new BehaviorSubject<OrganizationViewModel | null>(null);

  private _organizationList: ProfileOrganization[] = [];
  private _organizationList$ = new BehaviorSubject<ProfileOrganization[]>([]);

  get currentOrganization() {
    return this._currentOrganization;
  }

  get currentOrganization$() {
    return this._currentOrganization$.asObservable();
  }

  get organizationList() {
    return this._organizationList;
  }

  get organizationList$() {
    return this._organizationList$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private themeService: ThemeService,
    private profileService: ProfileService
  ) {
    this.authService.user$.subscribe(async user => {
      if (!user) return;

      try {
        const { data } = await lastValueFrom(this.profileService.listOrganizations(user._id));
        this._organizationList = data;
        this._organizationList$.next(data);
      } catch (error) {
        throw error;
      }
    });
  }

  private get(id: string) {
    return this.http.get<Result<OrganizationViewModel>>(`${this.url}/${id}`);
  }

  async selectOrganization(id: string | null) {
    try {
      if (!id) {
        this._currentOrganization = null;
        this._currentOrganization$.next(null);

        // this._organizationList = [];
        // this._organizationList$.next([]);

        return;
      }

      localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID, id);
      const { data } = await lastValueFrom(this.get(id));
      this._currentOrganization = data;
      this._currentOrganization$.next(data);

      const themeParemeters: ThemeParameters = {
        primaryColor: data.primaryColor,
        secondaryColor: data.secondaryColor
      };

      this.themeService.setThemeParameters(themeParemeters);
    } catch (error) {
      this._currentOrganization = null;
      this._currentOrganization$.next(null);
      throw error;
    }
  }
}
